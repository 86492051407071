body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// TODO enhance this

body {
  background: black;
  font-weight: bold;
  overflow-x: hidden !important;
}

#root {
  color: white;
}

.four-oh-four {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  background-color: black;
  transition: opacity 300ms ease-out;
  background-position: center center;
  background-repeat: no-repeat;
}
.four-oh-four .dJAX_internal {
  opacity: 0;
}
input {
  caret-color: transparent;
  font-size: 14px;
  background-color: black !important;
  width: 100%;
  color: #1ff042;
  outline: none;
  border: none;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: bold;
  margin-top: -1px;
  margin-left: -2px;
  letter-spacing: inherit;
  text-shadow: 0 0 2px rgba(31, 240, 66, 0.75);
}

.terminal {
  position: relative;
  margin: 4rem;
}

.terminal .prompt,
.welcomeText,
.result {
  color: #1ff042;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.15em;
  white-space: pre-wrap;
  text-shadow: 0 0 2px rgba(31, 240, 66, 0.75);
}
.terminal .prompt:before {
  content: "guest:~$";
  display: flex;
  margin-right: 4px;
  color: #0078d4;
  text-shadow: 0 0 2px rgba(0, 120, 212, 0.75);
}
.terminal .new-output {
  display: flex;
}

a {
  color: #ffd700;
  text-shadow: 0 0 2px rgba(255, 215, 0, 0.75);
  text-decoration: none;
}

.highlight {
  color: #ffd700;
  text-shadow: 0 0 2px rgba(255, 215, 0, 0.75);
  display: contents;
}

a:hover {
  text-decoration: underline;
}

img {
  padding: 10px;
  vertical-align: middle;
}

.new-output {
  margin-top: 0;
}

.error {
  color: #e50000;
  text-shadow: 0 0 2px rgba(229, 0, 0, 0.75);
}

// .result {
//   &::before {
//     content: ":";
//     margin-right: 4px;
//   }
// }

.copyright {
  font-size: 12px;
}

.separator {
  overflow: hidden;
}

@media (max-width: 576px) {
  .terminal {
    margin: 1rem;
  }
  input,
  .terminal .prompt,
  .welcomeText,
  .result {
    font-size: 12px;
  }
}

.m-t-0 {
  margin-top: 0;
}
.m-b-0 {
  margin-bottom: 0;
}
